import { DarkModeState, TOGGLE_DARK_MODE } from './types';
import { bakeLocalStorage, readLocalStorage } from '@helpers/storage';

export default function darkModeReducer(
	state = readLocalStorage('darkMode'),
	action: any
): DarkModeState {
	// Check to see what type of action is being fired
	switch (action.type) {
		case TOGGLE_DARK_MODE:
			bakeLocalStorage('darkMode', action.payload);
			return action.payload;
		default:
			// Return state by default if nothing else is met
			return state;
	}
}
