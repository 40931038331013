import { createTheme } from '@mui/material';
import { darken, lighten } from 'polished';
import { pxToRem } from '@helpers/common';
import palette from '@theme/palette';
import { grey } from '@mui/material/colors';

export default (theme) => ({
	palette: {
		error: {
			light: lighten(0.3, theme.palette.error.main),
		},
		text: palette.dark.text,
		mode: 'light',
	},
	components: {
		MuiCircularProgress: {
			styleOverrides: {
				root: {
					color: theme.palette.common.white,
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					color: theme.palette.common.white,
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					width: '100%',
					paddingTop: theme.spacing(3),
					paddingBottom: theme.spacing(3),
				},
				contained: {
					backgroundColor: theme.palette.common.white,
					color: theme.palette.common.black,

					'&:hover': {
						backgroundColor: darken(0.2, theme.palette.common.white),
					},
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					color: theme.palette.common.white,
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				root: {
					'&.MuiInput-root': {
						color: grey[800],
					},
				},
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					color: theme.palette.common.white,
					textTransform: 'none',
					// fontWeight: 'bold',

					'&.Mui-error': {
						color: theme.palette.error.light,
					},
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontSize: 13,
				},
			},
		},

		MuiCardActionArea: {
			styleOverrides: {
				root: {
					'& + .MuiCardActions-spacing': {
						paddingTop: theme.spacing(2),
					},
				},
			},
		},

		MuiCardActions: {
			styleOverrides: {
				spacing: {
					padding: theme.spacing(2),
					paddingTop: theme.spacing(0),
				},
			},
		},

		MuiCard: {
			styleOverrides: {
				root: {
					backgroundColor: theme.palette.common.white,
					color: theme.palette.common.black,
					borderRadius: theme.shape.borderRadius,

					'.MuiTypography-caption': {
						color: theme.palette.primary.main,
					},
				},
			},
		},

		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundColor: theme.palette.common.white,
					color: theme.palette.common.black,
					borderRadius: theme.shape.borderRadius,
				},
			},
		},

		MuiInputLabel: {
			styleOverrides: {
				filled: {
					color: theme.palette.primary.main,
				},
			},
		},

		// .css-1poqf85-MuiInputBase-root-MuiFilledInput-root:hover:not(.Mui-disabled):before
		MuiFilledInput: {
			styleOverrides: {
				// root: {
				// 	// backgroundColor: defaultTheme.palette.common.white,
				// },
				root: {
					backgroundColor: theme.palette.common.white,
					borderRadius: theme.shape.borderRadius,
					overflow: 'hidden',
					color: theme.palette.common.black,

					'&:before': {
						borderBottomStyle: 'none',
					},

					'&:after': {
						borderBottomColor: theme.palette.primary.light,
					},

					'&:hover': {
						backgroundColor: theme.palette.common.white,

						'&:not(.Mui-disabled)': {
							'&:before': {
								borderBottomStyle: 'none',
							},
						},
					},

					'&.Mui-focused': {
						backgroundColor: theme.palette.common.white,
					},

					'&.Mui-disabled': {
						'&:before': {
							borderBottomStyle: 'none',
						},
					},

					'&.Mui-error': {
						'&:after': {
							borderBottomLeftRadius: theme.shape.borderRadius,
							borderBottomRightRadius: theme.shape.borderRadius,
						},
					},
				},
			},
		},
	},
	typography: {
		button: {
			fontWeight: 600,
			letterSpacing: pxToRem(1.92),
		},
		overline: {
			fontSize: pxToRem(11),
			fontWeight: 600,
			letterSpacing: pxToRem(2.88),
		},
		h1: {
			fontSize: pxToRem(46),
		},
		h2: {
			fontSize: pxToRem(20),
			'&.MuiTypography-gutterBottom': {
				marginBottom: pxToRem(20),
			},
		},
		h3: {
			fontSize: pxToRem(20),
		},
	},
});
