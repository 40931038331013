export const TOGGLE_DARK_MODE = 'TOGGLE_DARK_MODE';

export type DarkModeState = boolean | null;

interface ToggleDarkModeAction {
	type: typeof TOGGLE_DARK_MODE;
	payload: boolean;
}

export type DarkModeTypes = ToggleDarkModeAction;
