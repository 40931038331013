import { User } from '@models/user';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_UPDATE_FROM_TOKEN = 'USER_UPDATE_FROM_TOKEN';
export type UserState =
	| (User & { token: AccessToken; refreshToken: RefreshToken; expiry: string })
	| null;
export interface LoginUserAction {
	type: typeof USER_LOGIN;
	payload: { user: User; token: AccessToken; refreshToken: RefreshToken };
}
export interface LogoutUserAction {
	type: typeof USER_LOGOUT;
}
export interface UpdateUserAction {
	type: typeof USER_UPDATE;
	payload: { user?: User; token?: AccessToken; refreshToken?: RefreshToken };
}
export interface UpdateUserFromTokenAction {
	type: typeof USER_UPDATE_FROM_TOKEN;
	payload: { user?: User; token?: AccessToken; refreshToken?: RefreshToken };
}

export type AccessToken = string;
export type RefreshToken = string;
export type UserActionTypes =
	| LoginUserAction
	| LogoutUserAction
	| UpdateUserAction
	| UpdateUserFromTokenAction;
