import { Theme } from '@mui/material/styles';
import { IconButtonPropsSizeOverrides } from '@mui/material/IconButton/IconButton';

// ----------------------------------------------------------------------

export default function IconButton(theme: Theme) {
	return {
		MuiIconButton: {
			variants: [
				{
					props: { color: 'default' },
					style: {
						'&:hover': { backgroundColor: theme.palette.action.hover },
					},
				},
				{
					props: { color: 'inherit' },
					style: {
						'&:hover': { backgroundColor: theme.palette.action.hover },
					},
				},
				{
					props: { size: 'large' },
					style: {
						'.MuiSvgIcon-root': {
							fontSize: '2rem',
						},
					},
				},
			],

			styleOverrides: {
				root: {},
			},
		},
	};
}
