import { combineReducers } from 'redux';

import UserReducer from './user/reducer';
import NavigationReducer from './navigation/reducer';
import DarkModeReducer from './darkMode/reducer';
import { AppState } from './types';

export default combineReducers<AppState>({
	user: UserReducer,
	navigation: NavigationReducer,
	darkMode: DarkModeReducer,
});
