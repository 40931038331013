export const NAVIGATION_OPEN = 'NAVIGATION_OPEN';
export const NAVIGATION_SET_MODE = 'NAVIGATION_SET_MODE';

export type NavigationState = { open: boolean | null; mode: 'standard' | 'admin' };

interface NavigationOpenAction {
	type: typeof NAVIGATION_OPEN;
	payload: boolean;
}

interface NavigationModeAction {
	type: typeof NAVIGATION_SET_MODE;
	payload: 'standard' | 'admin';
}

export type NavigationTypes = NavigationOpenAction | NavigationModeAction;
