import { Theme } from '@mui/material/styles';
import { pxToRem } from '@helpers/common';
import { grey } from '@mui/material/colors';
import { darken, lighten } from 'polished';
import { alpha } from '@mui/material';

// ----------------------------------------------------------------------

export default function Button(theme: Theme) {
	return {
		MuiButton: {
			variants: [
				{
					props: { variant: 'inversed' },
					style:
						theme?.palette?.mode === 'light'
							? {
									backgroundColor: theme.palette.common.black,
									color: theme.palette.common.white,
									'&:hover': {
										backgroundColor: grey['900'],
										color: theme.palette.common.white,
									},
							  }
							: {
									backgroundColor: theme.palette.common.white,
									color: theme.palette.common.black,
									'&:hover': {
										backgroundColor: grey['200'],
										color: theme.palette.common.black,
									},
							  },
				},
				{
					props: { variant: 'dark' },
					style: {
						backgroundColor: alpha(theme.palette.common.black, 0.3),
						color: theme.palette.common.white,
						'&:hover': {
							backgroundColor: alpha(theme.palette.common.black, 0.2),
							color: theme.palette.common.white,
						},
					},
				},
				{
					props: { variant: 'overlay', color: 'primary' },
					style:
						theme?.palette?.mode === 'light'
							? {
									backgroundColor: theme.palette.primary.dark,
									color: theme.palette.common.white,
									'&:hover': {
										backgroundColor: darken(0.1, theme.palette.primary.dark),
										color: theme.palette.common.white,
									},
							  }
							: {
									backgroundColor: theme.palette.primary.light,
									color: theme.palette.common.white,
									'&:hover': {
										backgroundColor: lighten(0.1, theme.palette.primary.dark),
										color: theme.palette.common.white,
									},
							  },
				},
				{
					props: { variant: 'overlay', color: 'secondary' },
					style:
						theme?.palette?.mode === 'light'
							? {
									backgroundColor: theme.palette.secondary.dark,
									color: theme.palette.common.white,
									'&:hover': {
										backgroundColor: darken(0.2, theme.palette.secondary.dark),
										color: theme.palette.common.white,
									},
							  }
							: {
									backgroundColor: theme.palette.secondary.light,
									color: theme.palette.common.white,
									'&:hover': {
										backgroundColor: lighten(0.2, theme.palette.secondary.dark),
										color: theme.palette.common.white,
									},
							  },
				},
			],
			styleOverrides: {
				root: {
					textTransform: 'uppercase',
					letterSpacing: pxToRem(1),
					paddingLeft: pxToRem(20),
					paddingRight: pxToRem(20),
					paddingTop: pxToRem(8),
					paddingBottom: pxToRem(8),
					borderRadius: theme.shape.borderRadius,

					'&:hover': {
						boxShadow: 'none',
					},
				},
				sizeLarge: {
					height: 48,
				},
				containedInherit: {
					color: theme.palette.grey[800],
					boxShadow: theme.customShadows.z8,
					'&:hover': {
						backgroundColor: theme.palette.grey[400],
					},
				},
				containedPrimary: {
					boxShadow: theme.customShadows.primary,
				},
				containedSecondary: {
					boxShadow: theme.customShadows.secondary,
				},
				outlinedInherit: {
					border: `1px solid ${theme.palette.grey[500_32]}`,
					'&:hover': {
						backgroundColor: theme.palette.action.hover,
					},
				},
				textInherit: {
					'&:hover': {
						backgroundColor: theme.palette.action.hover,
					},
				},
			},
		},
	};
}

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		inversed: true;
		overlay: true;
		dark: true;
	}
}
