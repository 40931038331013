import md5 from 'md5';
import { CDN_LINK } from './api';
import { UserState } from '@store/user/types';
import { Route, RouteChild } from '@models/navigation';
import queryString from 'query-string';
import { navigate } from '@reach/router';
import { CheckoutCardColour } from '@models/checkout';
import { Address } from '@models/address';
import { amber, grey } from '@mui/material/colors';

export const cardColours = {
	silver: grey['400'],
	gold: amber['500'],
	black: grey['900'],
};

export function pxToRem(value: number) {
	return `${value / 16}rem`;
}

export const validateUrl = (value) => {
	return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
		value
	);
};

export const formatAddress = (address?: Address) =>
	!!address
		? `${address?.firstName} ${address?.lastName}, ${address.streetOne}, ${address.county}, ${address.country}, ${address.postcode}`
		: null;

export const getCardColour = (colour?: CheckoutCardColour) =>
	(colour === 'black' && 'Graphite') ||
	(colour === 'silver' && 'Gunmetal') ||
	(colour === 'gold' && 'Gold') ||
	null;

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
export const countryToFlag = (isoCode?: string) => {
	if (!isoCode) return null;

	return typeof String.fromCodePoint !== 'undefined'
		? isoCode
				.toUpperCase()
				.replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
		: isoCode;
};

export const navigateParams = (params: { [x: string]: any }, replace?: boolean) =>
	navigate(
		`?${queryString.stringify({
			...(params ?? {}),
		})}`,
		{ replace: !!replace }
	);

// export function getHostname() {
// 	if (process.env.NODE_ENV === 'development') {
// 		return process.env.HOST_NAME || `localhost`;
// 	}
//
// 	return window.location.hostname;
// }

export const routeMatches = (route: Route | RouteChild | undefined) => {
	if (!route) return false;
	if (route?.match) return true;
	if (route?.children?.some(routeMatches)) return true;
};

// TODO: fix TS error
export function returnProfilePicture(user: UserState & {avatar: any}) {
	if (user?.avatar && !!user?.avatar) {
		// If our string includes https it's a social avatar so we should display that instead
		return user.avatar?.includes('https') ? user?.avatar : `${CDN_LINK}/avatar/${user?.avatar}`;
	}

	// if (user?.gravatar) return `https://secure.gravatar.com/avatar/${user?.gravatar}?d=identicon&s=256`;
	if (user?.email) return `https://www.gravatar.com/avatar/${md5(user?.email)}?d=identicon&s=256`;
	return `https://www.gravatar.com/avatar/null?d=identicon&s=256`;
}

// capitalised the first letter of a sentence, useful for Formik form errors
export function capitalise(text: string) {
	return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
}

// Common methods
export function isValidJSON(string: string) {
	return /^[\],:{}\s]*$/.test(
		/* eslint-disable */
		string
			.replace(/\\["\\\/bfnrtu]/g, '@')
			.replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
			.replace(/(?:^|:|,)(?:\s*\[)+/g, '')
		/* eslint-enable */
	);
}

export function isValidStringify(value: string) {
	return (typeof value === 'object' && value !== null) || Array.isArray(value);
}

export const keyBy = (
	array: { [x: string]: any }[],
	key: string,
	formatter?: (data: { [x: string]: any }) => any
) =>
	(array || []).reduce(
		(r, x) => ({ ...r, [key ? x[key] : x]: formatter ? formatter(x) : x }),
		{}
	);

export function trimWords(text: string, characterLength: number = 130) {
	const trimmedString = text.substr(0, characterLength);
	return trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')));
}

export function formatPhone(phoneNumber) {
	return phoneNumber?.replace(/\s+/g, '')?.replace(/(.)(\d{4})(\d)/, '+44 ($1)$2 $3');
}
